import consumer from "./consumer"

consumer.subscriptions.create("MessageChannel", {
  connected() {
    console.log("------------connected to the channel--------------")
  },

  disconnected() {
    console.log("------------disconnected--------------")
  },

  received(data) {
    var id = data.id
    $('#messageCampaign-' + id).append('<div class="sent-message"><p>' + data['conversation'].message_text +'</p></div>')
    $('.sms_conversation')[0].innerHTML = data['partial_html']
  }
});
